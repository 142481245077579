<template>
  <material-card
    color="pink darken-4"
    title="Lab Results Requiring Attention"
    icon="fal fa-fw fa-file-exclamation"
    class="mb-10"
    :text="$attrs.text"
  >
    <v-data-table
      :headers="tableHeaders"
      :items="lab_results"
      :server-items-length="total"
      :loading="loading"
      :hide-default-footer="total < 7"
      :footer-props="{
        itemsPerPageOptions: [ 6, 15 ],
        itemsPerPageText: 'Lab results per page:',
      }"
      :options.sync="options"
      :items-per-page="6"
      item-key="uuid"
      no-results-text="All caught up"
      no-data-text="All caught up"
      class="mb-n4"
      @click:row="(item) => { labResultLink(item) }"
    >
      <template #item.client="{ item }">
        <client-icon
          :key="item.client.uuid"
          :name="item.client.name"
          :icon="item.client.icon"
        />
      </template>
      <template #item.created_at="{ item }">
        {{ item.created_at | moment('h:mm a') }}
      </template>
      <template #item.patient="{ item }">
        <span data-private>{{ item.patient.first_name }} {{ item.patient.last_name }}</span>
      </template>
      <template #item.provider="{ item }">
        <template v-if="item.provider">
          {{ item.provider.first_name.charAt(0) }}. {{ item.provider.last_name }}
          <v-icon
            v-if="item.created_at > item.provider.advanced_asp"
            key="advanced_asp_icon"
            color="cyan"
            size="14"
            title="Advanced ASP Provider"
          >
            fas fa-star
          </v-icon>
        </template>
        <v-icon
          v-else
          color="grey lighten-1"
        >
          fal fa-horizontal-rule
        </v-icon>
      </template>
      <template #item.sample_type="{ item }">
        <source-icon
          v-if="item.sample_type"
          :key="item.sample_source"
          :name="item.sample_type"
          :source="item.sample_source"
        />
      </template>
      <template
        #item.status="{ item }"
      >
        <status-chip
          :key="item.status"
          :status="item.status"
          :reason="item.statuses ? item.statuses[0]?.reason : null"
        />
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    SourceIcon: () => import('@/components/source/SourceIcon.vue'),
    ClientIcon: () => import('@/components/ClientIcon.vue'),
    StatusChip: () => import('@/components/StatusChip.vue'),
  },
  data () {
    return {
      loading: false,
      total: null,
      lab_results: [],
      options: {
        itemsPerPage: 6,
      },
      timer: null,
    }
  },
  computed: {
    ...mapState('charts', ['lab_results_monthly']),
    tableHeaders () {
      var headers = [
        {
          text: this.$options.filters.capitalize(this.$tc('client', 1)),
          value: 'client',
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t('Result ID')),
          value: 'external_id',
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t('received')),
          value: 'created_at',
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$tc('provider', 1)),
          value: 'provider',
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t('source')),
          value: 'sample_type',
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t('status')),
          value: 'status',
          sortable: false,
        },
      ]
      if (!this.$auth.check({ clients: ['view','edit','*'] })) {
        headers = headers.filter(x => x.value && x.value != 'client')
      }
      return headers
    },
    statuses () {
      let statuses = [
        'new',
        'high confidence',
        'quality assurance',
        'pending approval',
        'needs review',
        'needs translation',
      ]

      if (this.$auth.check({ lab_results: ['extract','*'] })) {
        statuses.push('data extraction')
      }

      if (this.$auth.check({ allergens: ['edit','*'], diagnoses: ['edit','*'], organisms: ['edit','*'], resistances: ['edit','*'] })) {
        statuses.push('unclassified data')
      }

      if (this.$auth.check({ clients: ['edit','*'] })) {
        statuses.push('contact lab')
      }

      return statuses
    },
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.fetchLabResults()
      },
    },
  },
  created () {
    this.fetchLabResults()
    this.timer = setInterval(() => {
      this.fetchLabResults()
    }, 180000)
  },
  methods: {
    ...mapMutations("charts", ["setMonthlyChartData"]),
    fetchLabResults () {
      this.loading = true
      this.axios.get('lab_results', {
        params:
          {
            page: this.options.page,
            count: this.options.itemsPerPage,
            order: 'asc',
            status: this.statuses,
            quick: true,
          },
      }).then((response) => {
        this.lab_results = response.data.data
        this.total = response.data.total
        this.loading = false
      }).catch(error => {
        this.loading = false
        if (error.response?.data?.message) {
          this.$toast.error(error.response.data.message)
        }
      })
    },
    labResultLink(item) {
      var destination
      switch(item.status) {
        case 'needs translation':
          destination = 'Translate Interpretation'
          break
        case 'data extraction':
          destination = 'Data Extraction'
          break
        case 'needs review' && item.interpretation:
        case 'pending approval':
          destination = 'Review Interpretation'
          break
        default:
          destination = 'Interpretation'
      }
      this.$router.push({ name: destination, params: { uuid: item.uuid } })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timer)
    next()
  },
}
</script>

<style lang="css">
  .row-pointer tbody tr :hover {
    cursor: pointer;
  }
  .v-badge {
    width: 100%
  }
</style>
